import React from "react";
import { Link } from "gatsby";

import "./footer.css";

const date = new Date();
const year = date.getFullYear();

const Footer = props => (
  <div className="footer">
    <ul className="footer-links">
      <Link className="footer-link" to="/about">
        <li>about</li>
      </Link>
      <Link className="footer-link" to="/programs">
        <li>programs</li>
      </Link>
      <Link className="footer-link" to="/contact">
        <li>contact</li>
      </Link>
    </ul>
    <p className="footer-legal">{`copyright ${year}`}</p>
  </div>
);

export default Footer;
