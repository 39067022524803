import React, { Component } from "react";
import { Link } from "gatsby";

import cx from "classnames";
import "./navbar.css";

class NavBar extends Component {
  render() {
    const { selectedPage } = this.props;
    return (
      <div className="navbar">
        <h1 className="navbar-logo">
          <Link to="/">Insight Education</Link>
        </h1>

        <div id="menuToggle" className="navbar-links-mobile">
          <input type="checkbox" />
          <span />
          <span />
          <span />
          <ul id="menu">
            <Link
              className={cx("navbar-link", {
                "navbar-link--selected": selectedPage === "home"
              })}
              to="/"
            >
              <li>home</li>
            </Link>
            <Link
              className={cx("navbar-link", {
                "navbar-link--selected": selectedPage === "about"
              })}
              to="/about"
            >
              <li>about</li>
            </Link>
            <Link
              className={cx("navbar-link", {
                "navbar-link--selected": selectedPage === "programs"
              })}
              to="/programs"
            >
              <li>programs</li>
            </Link>
            <Link
              className={cx("navbar-link", {
                "navbar-link--selected": selectedPage === "contact"
              })}
              to="/contact"
            >
              <li>contact</li>
            </Link>
          </ul>
        </div>

        <ul className=" navbar-links-desktop">
          <Link
            className={cx("navbar-link", {
              "navbar-link--selected": selectedPage === "home"
            })}
            to="/"
          >
            home
          </Link>
          <Link
            className={cx("navbar-link", {
              "navbar-link--selected": selectedPage === "about"
            })}
            to="/about"
          >
            about
          </Link>
          <Link
            className={cx("navbar-link", {
              "navbar-link--selected": selectedPage === "programs"
            })}
            to="/programs"
          >
            programs
          </Link>
          <Link
            className={cx("navbar-link", {
              "navbar-link--selected": selectedPage === "contact"
            })}
            to="/contact"
          >
            contact
          </Link>
        </ul>
      </div>
    );
  }
}

export default NavBar;
