import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";

import Layout from "../components/Layout";

import SEO from "../components/seo";

const Programs = () => (
  <Layout selectedPage="programs">
    <SEO
      title="Programs"
      keywords={[`Mental Health Education`, `Mental Health Programs`]}
    />
    <StaticQuery
      query={graphql`
        query {
          allPrismicProgram {
            edges {
              node {
                uid
                data {
                  priority
                  title {
                    text
                  }
                  summary {
                    text
                  }
                  cost {
                    text
                  }
                  duration {
                    text
                  }
                  body {
                    __typename
                    ... on PrismicProgramBodyText {
                      id
                      slice_type
                      primary {
                        text {
                          text
                        }
                      }
                    }

                    ... on PrismicProgramBodyList {
                      id
                      slice_type
                      primary {
                        listprefix {
                          text
                        }
                      }
                      items {
                        listitem {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={results => {
        const programs = results.allPrismicProgram.edges || [];
        const sortedPrograms = programs.sort((a, b) => {
          // items without a priority set are placed at end of list
          if (!a.node.data.priority) return 1;
          if (!b.node.data.priority) return -1;
          if (a.node.data.priority > b.node.data.priority) return 1;
          if (a.node.data.priority < b.node.data.priority) return -1;
          return 0;
        });

        return (
          <section>
            <h3>All Programs</h3>
            <ul className="program-list">
              {sortedPrograms.map(({ node }) => {
                return (
                  <li key={node.uid} className="program-list--card">
                    <h4>{node.data.title.text}</h4>
                    <p>{node.data.summary.text}</p>
                    <Link className="button" to={`/programs/${node.uid}`}>
                      More Information
                    </Link>
                  </li>
                );
              })}
            </ul>
          </section>
        );
      }}
    />
  </Layout>
);

export default Programs;
